import Image from '../Image';

import { getImageSrcSet, getResizeUrl, getWebpResource } from 'common/utils/image';

type WebpImageProps = {
  className?: string;
  width: string;
  height: string;
  src: string;
  alt?: string;
  loading?: 'lazy' | 'eager';
};

const WebpImage = ({ className, width, height, src, alt, loading }: WebpImageProps) => {
  return (
    <Image
      className={className}
      width={width}
      height={height}
      sources={getWebpResource(src, width)}
      src={getResizeUrl(src, width)}
      srcSet={getImageSrcSet(src, width)}
      alt={alt}
      loading={loading}
    />
  );
};

export default WebpImage;
