import Link from 'next/link';

import { routes } from 'common/utils/constant';
import type { LogoProps } from 'ui/Logo';
import Logo from 'ui/Logo';

import styles from './linkLogo.module.less';

type LinkLogoProps = {
  theme: LogoProps['theme'];
};

const LinkLogo = ({ theme }: LinkLogoProps) => {
  return (
    <Link href={routes.HOME}>
      <a>
        <Logo className={styles.logo} width="120" height="28" theme={theme} loading="eager" />
      </a>
    </Link>
  );
};

export default LinkLogo;
