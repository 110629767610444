import { Layout } from 'antd';
import useFitMaxWidth from 'common/hooks/useFitMaxWidth';
import useHeaderBGStatus from 'common/hooks/useHeaderBGStatus';
import { useAppSelector } from 'common/hooks/useReduxs';
import LinkLogo from '../LinkLogo';
import AppMenu from './AppMenu';
import HamArea from './HamArea';
import LoginBtn from './LoginBtn';

import styles from './appHeader.module.less';

const { Header } = Layout;

type AppHeader = {
  transparent?: boolean;
  isOffice?: boolean;
};

const langCodeMap: Map<string, string> = new Map();
langCodeMap.set('en', 'en');
langCodeMap.set('zh_CN', 'cn');
langCodeMap.set('zh_TW', 'tw');

const AppHeader = ({ transparent = false, isOffice = false }: AppHeader) => {
  // const { data } = useGetWebpackQuery();
  const isLogin = useAppSelector((state) => state.user.isLogin);
  const isHeaderBGChanged = useHeaderBGStatus();
  const isFitMaxWidth = useFitMaxWidth(1000);
  const className = isHeaderBGChanged
    ? styles.lightHeader
    : transparent
    ? styles.transparent
    : undefined;
  return (
    <Header className={`${styles.antLayoutHeader} ${className}`}>
      <div className={styles.containerFluid}>
        <div className={styles.header}>
          {isOffice && <div className={styles.space}>&nbsp;</div>}
          <div className={isOffice ? styles.logo : undefined}>
            <LinkLogo theme={isHeaderBGChanged ? 'dark' : 'light'} />
          </div>
          {/* Mobile view shows HamArea otherwise MenuContainer */}
          <HamArea isHeaderBGChanged={isHeaderBGChanged} />
          {!isFitMaxWidth && (
            <div className={styles.menuContainer}>
              <AppMenu isHeaderBGChanged={isHeaderBGChanged} transparent={transparent} />
              {!isLogin && <LoginBtn isHeaderBGChanged={isHeaderBGChanged} />}
            </div>
          )}
        </div>
      </div>
    </Header>
  );
};

export default AppHeader;
