import Link from 'next/link';

import styles from './footerLinks.module.less';

export type LinkItem = {
  text: string;
  href: string;
};

type FooterLinksProps = {
  title: string;
  items: LinkItem[];
};

const FooterLinks = ({ title, items }: FooterLinksProps) => {
  return (
    <div className={styles.footerLinks}>
      <div className={styles.title}>{title}</div>
      <ul className={styles.links}>
        {items.map((item, index) => (
          <li key={index}>
            <Link href={item.href}>
              <a>{item.text}</a>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FooterLinks;
