import { CDN_HOST } from 'common/utils/constant';
import WebpImage from '../WebpImage';

export type LogoProps = {
  className?: string;
  width: string;
  height: string;
  theme: 'dark' | 'light';
  loading?: 'lazy' | 'eager';
};

const Logo = ({ className = '', width, height, theme, loading }: LogoProps) => {
  const whiteImgSrc = `${CDN_HOST}/pub/img/logo/logo_v1_white.png`;
  const blackImgSrc = `${CDN_HOST}/pub/img/logo/logo_v1_block.png`;

  return (
    <WebpImage
      className={className}
      width={width}
      height={height}
      src={theme === 'dark' ? blackImgSrc : whiteImgSrc}
      alt="Slasify Logo Image"
      loading={loading}
    />
  );
};

export default Logo;
