import {
  FacebookOutlined,
  GlobalOutlined,
  HeartFilled,
  LinkedinOutlined,
  YoutubeFilled,
} from '@ant-design/icons';
import { Layout, Select } from 'antd';
import { useRouter } from 'next/router';
import { useIntl } from 'react-intl';

import { constant, routes } from 'common/utils/constant';
import { getThisYear } from 'common/utils/dates';
import Logo from 'ui/Logo';
import BlankLink from '../BlankLink';
import type { LinkItem } from './FooterLinks';
import FooterLinks from './FooterLinks';
import HiddenLinks from './HiddenLinks';

import config from 'common/config';
import { useAppSelector } from 'common/hooks/useReduxs';
import { useGetChangeLangToMutation } from 'common/services/webpack';
import { setLocaleCookie } from 'common/utils/i18n';
import styles from './appFooter.module.less';

const { Footer } = Layout;
const { Option } = Select;
const { zh_tw, zh_cn, en_us } = constant;

const AppFooter = () => {
  const intl = useIntl();
  const router = useRouter();
  const thisYear = getThisYear();
  const [getChangeLangTo] = useGetChangeLangToMutation();
  const isLogin = useAppSelector((state) => state.user.isLogin);
  const userInfo = useAppSelector((state) => state.user.userInfo);
  const slasifyItems: LinkItem[] = [
    {
      text: intl.formatMessage({
        id: 'footer.link.feature',
        defaultMessage: 'Features',
      }),
      href: routes.FEATURE,
    },
    {
      text: intl.formatMessage({
        id: 'footer.link.help',
        defaultMessage: 'Help',
      }),
      href: routes.HELP,
    },
    {
      text: intl.formatMessage({
        id: 'footer.link.contact',
        defaultMessage: 'Contact Us',
      }),
      href: routes.CONTACT,
    },
    {
      text: intl.formatMessage({
        id: 'footer.link.about',
        defaultMessage: 'About Us',
      }),
      href: routes.ABOUT_US,
    },
    {
      text: intl.formatMessage({
        id: 'footer.link.terms',
        defaultMessage: 'Terms',
      }),
      href: routes.TERMS,
    },
    {
      text: intl.formatMessage({
        id: 'footer.link.privacy',
        defaultMessage: 'Privacy',
      }),
      href: routes.PRIVACY,
    },
  ];

  const discoverItems: LinkItem[] = [
    {
      text: intl.formatMessage({
        id: 'footer.link.payroll',
        defaultMessage: 'Global Payroll',
      }),
      href: `${config.baseUrl}/${intl.locale || 'en'}/payroll`,
    },
    {
      text: intl.formatMessage({
        id: 'footer.link.eor',
        defaultMessage: 'Employer of Records',
      }),
      href: `${config.baseUrl}/${intl.locale || 'en'}/eor`,
    },
    {
      text: intl.formatMessage({
        id: 'footer.link.remote.jobs',
        defaultMessage: 'Remote Jobs',
      }),
      // href: routes.REMOTE_JOBS,
      href: `${config.jobsUrl}/${intl.locale || 'en'}`,
    },
    {
      text: intl.formatMessage({
        id: 'footer.link.insight',
        defaultMessage: 'Insight',
      }),
      href: `${config.blogUrl}/${intl.locale === 'tw' ? '' : intl.locale === 'cn' ? 'zh-cn' : 'en'}`,
    },
  ];

  const langCodeMap: Map<string, string> = new Map();
  langCodeMap.set('en', 'en');
  langCodeMap.set('cn', 'zh_CN');
  langCodeMap.set('tw', 'zh_TW');

  const onLangChange = async (langCode: string) => {
    if (isLogin) {
      await getChangeLangTo(langCodeMap.get(langCode)!);
    }
    setLocaleCookie(langCode);
    window.location.href = `/${langCode}/${router.asPath}`;
  };

  const reviewItems: LinkItem[] = [
    {
      text: 'Facebook',
      href: 'https://www.facebook.com/slasify/reviews/',
    },
    {
      text: 'Google',
      href: 'https://goo.gl/wxk6jT',
    },
    {
      text: intl.formatMessage({
        id: 'footer.link.status',
        defaultMessage: 'Status',
      }),
      href: 'https://stats.uptimerobot.com/NL1mzTN5p',
    },
  ];

  return (
    <Footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.upper}>
          <div className={styles.left}>
            <Logo width="133" height="30" className={styles.logo} theme="light" />
            <div className={styles.footerSlogan}>
              <p>YOUR OWN REMOTE VIRTUAL TEAM IS STANDING BY</p>
            </div>
            <Select defaultValue={intl.locale} className={styles.select} onChange={onLangChange}>
              <Option value={en_us}>English</Option>
              <Option value={zh_cn}>中文 ( 简体 )</Option>
              <Option value={zh_tw}>中文 ( 繁體 )</Option>
            </Select>
            {/* 繼承Carlos註解: 為了笨死人的Google設定的隱藏連結，勿刪HiddenLinks */}
            <HiddenLinks />
          </div>
          <div className={styles.right}>
            <FooterLinks title="Slasify" items={slasifyItems} />
            <FooterLinks
              title={intl.formatMessage({
                id: 'footer.row.discover',
                defaultMessage: 'Discover',
              })}
              items={discoverItems}
            />
            <FooterLinks
              title={intl.formatMessage({
                id: 'footer.row.review',
                defaultMessage: 'Review',
              })}
              items={reviewItems}
            />
          </div>
        </div>
        <div className={styles.lower}>
          <div className={styles.socialLinks}>
            <BlankLink
              href="https://www.facebook.com/slasify"
              content={<FacebookOutlined className={styles.icon} />}
              isTrusted={true}
            />
            <BlankLink
              href="https://www.linkedin.com/company/slasify"
              content={<LinkedinOutlined className={styles.icon} />}
              isTrusted={true}
            />
            <BlankLink
              href="https://www.youtube.com/channel/UCuW9Z3pKBXxQJAaW426zXrA"
              content={<YoutubeFilled className={styles.icon} />}
              isTrusted={true}
            />
            <BlankLink
              href={`${config.blogUrl}/${intl.locale === 'tw' ? '' : intl.locale === 'cn' ? 'zh-cn' : 'en'}`}
              content={<GlobalOutlined className={styles.icon} />}
              isTrusted={true}
            />
          </div>
          <div className={styles.copyRight}>
            <span>
              Build with <HeartFilled style={{ color: '#FF7273' }} /> in Singapore.
            </span>
            <div>Copyright {thisYear} Slasify. All rights reserved.</div>
          </div>
          {userInfo?.isChina ? (<div className={styles.icp}>
            <a href="https://beian.miit.gov.cn/">闽ICP备15006277号-3</a>
          </div>) : ''}
          <div className={styles.ipv}>IPv6</div>
        </div>
      </div>
    </Footer>
  );
};

export default AppFooter;
