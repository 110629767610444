import { constant } from 'common/utils/constant';
import { getHrefLangUrl } from 'common/utils/location';
import { useRouter } from 'next/router';

const { zh_tw, zh_cn, en_us, PATH_TW, PATH_CN, PATH_EN } = constant;

const HiddenLinks = () => {
  const router = useRouter();

  return (
    <div style={{ display: 'none' }}>
      <a key={zh_tw} href={getHrefLangUrl(PATH_TW, router.pathname)}>
        {zh_tw}
      </a>
      <a key={zh_cn} href={getHrefLangUrl(PATH_CN, router.pathname)}>
        {zh_cn}
      </a>
      <a key={en_us} href={getHrefLangUrl(PATH_EN, router.pathname)}>
        {en_us}
      </a>
    </div>
  );
};

export default HiddenLinks;
