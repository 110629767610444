import AppContent from 'ui/AppContent';
import AppFooter from 'ui/AppFooter';
import AppHeader from 'ui/AppHeader';
import HeadTitle from '../HeadTitle';

import {useAppDispatch} from 'common/hooks/useReduxs';
import {useGetWebpackQuery} from 'common/services/webpack';
import {setLocaleCookie} from 'common/utils/i18n';
import {useRouter} from 'next/router';
import {useEffect, useState} from 'react';

import {setIsLogin, setUserInfo} from 'common/redux/reducers/users';
import styles from './HCFLayout.module.less';

type HCFLayoutProps = {
    headerTransparent?: boolean;
    children: React.ReactNode;
    headTitle?: string;
};

const langCodeMap: Map<string, string> = new Map();
langCodeMap.set('en', 'en');
langCodeMap.set('zh_CN', 'cn');
langCodeMap.set('zh_TW', 'tw');

const HCFLayout = ({headTitle, headerTransparent = false, children}: HCFLayoutProps) => {
    const {data} = useGetWebpackQuery();
    const dispatch = useAppDispatch();
    const router = useRouter();
    const [renderPage, setRenderPage] = useState(false);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const hostname = window.location.hostname;

            if (data) {
                if (!data.hasLogin) {
                    if (hostname.includes('office')) {
                        if(localStorage.getItem('localDevelop') === 'true'){
                            // 本地开发需要做一下特殊处理，之后我会删掉
                            setRenderPage(true);
                        } else {
                            window.location.href = `${process.env.authUrl}/${router.locale}`;
                        }
                    } else {
                        setRenderPage(true);
                    }
                } else {
                    dispatch(setIsLogin(data.hasLogin));
                    dispatch(setUserInfo(data));
                    const newLangCode = router.locale;
                    const oldLangCode = data?.loginUser?.langCode?.name || '';

                    if (langCodeMap.get(oldLangCode) !== newLangCode) {
                        setLocaleCookie(langCodeMap.get(oldLangCode)!);
                        window.location.href = `/${langCodeMap.get(oldLangCode)}/${router.asPath}`;
                        return;
                    }
                    setRenderPage(true);
                }
            }
        }
    }, [data, router.locale, router.asPath, dispatch]);
    return data && renderPage ? (
        <>
            <HeadTitle title={headTitle || ''}/>
            <AppHeader transparent={headerTransparent}/>
            <AppContent className={headerTransparent ? styles.zeroMarginTop : undefined}>{children}</AppContent>
            <AppFooter/>
        </>
    ) : null;
};

export default HCFLayout;
