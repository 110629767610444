import { enquireScreen, unenquireScreen } from 'enquire-js';
import { useEffect, useState } from 'react';

const useFitMaxWidth = (maxWidth: number) => {
  const [isFitMaxWidth, setIsFitFitMaxWidth] = useState<boolean>(false);

  useEffect(() => {
    const query = `only screen and (max-width: ${String(maxWidth)}px)`;
    const handler = enquireScreen((isFit: boolean) => {
      setIsFitFitMaxWidth(isFit);
    }, query);

    return () => unenquireScreen(handler, query);
  }, [maxWidth]);

  return isFitMaxWidth;
};

export default useFitMaxWidth;
