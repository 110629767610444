import { CloseOutlined, MenuOutlined } from '@ant-design/icons';
import { Drawer } from 'antd';
import { useState } from 'react';

import Logo from '../../Logo';
import HamMenu from './HamMenu';

import styles from './hamArea.module.less';

type HamAreaProps = {
  isHeaderBGChanged: boolean;
};

const HamArea = ({ isHeaderBGChanged }: HamAreaProps) => {
  const [visible, setVisible] = useState<boolean>(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const CloseDrawer = () => {
    setVisible(false);
  };

  return (
    <div className={styles.hamArea}>
      <MenuOutlined
        onClick={showDrawer}
        className={isHeaderBGChanged ? styles.hamBtnDark : styles.hamBtnLight}
      />
      <Drawer
        placement="left"
        closable={true}
        onClose={CloseDrawer}
        visible={visible}
        headerStyle={{ borderBottom: 'none' }}
        bodyStyle={{ padding: '24px 0' }}
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
      >
        <Logo className={styles.logoDrawer} width="120" height="28" theme="light" loading="eager" />
        <HamMenu CloseDrawer={CloseDrawer} />
      </Drawer>
    </div>
  );
};

export default HamArea;
