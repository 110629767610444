import { Menu } from 'antd';
import config from 'common/config';
import { useGetWebpackQuery } from 'common/services/webpack';
import { routes } from 'common/utils/constant';
import { getCookie } from 'common/utils/cookie';
import Link from 'next/link';
import { MenuClickEventHandler } from 'rc-menu/lib/interface';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import EditBoxLineIcon from 'remixicon-react/EditBoxLineIcon';
import ListCheckIcon from 'remixicon-react/ListCheckIcon';
import LogoutCircleRLineIcon from 'remixicon-react/LogoutCircleRLineIcon';
import PriceTag3LineIcon from 'remixicon-react/PriceTag3LineIcon';
import Suitcase3LineIcon from 'remixicon-react/Suitcase3LineIcon';
import styles from './hamMenu.module.less';

type HamMenuProps = {
  CloseDrawer: MenuClickEventHandler;
};

const HamMenu = ({ CloseDrawer }: HamMenuProps) => {
  const intl = useIntl();
  const { data } = useGetWebpackQuery();
  const [remotePath, setRemotePath] = useState<string>('');
  const [remoteOfficePath, setRemoteOfficePath] = useState<string>('');
  const [loginPath, setLoginPath] = useState<string>('');
  const [blogPath, setBlogPath] = useState<string>('');
  useEffect(() => {
    setRemotePath(`${config.oldWebUrl}/${getCookie('NEXT_LOCALE') || 'en'}/remote-jobs`);
    setLoginPath(`${config.authUrl}/${getCookie('NEXT_LOCALE') || 'en'}/login`);
    setBlogPath(`${config.blogUrl}/${intl.locale === 'tw' ? '' : intl.locale === 'cn' ? 'zh-cn' : 'en'}`);
    setRemoteOfficePath(`${config.oldWebUrl}/console/v2/`);
  }, [intl.locale]);
  const items = [
    {
      key: 'sub1',
      label: intl.formatMessage({
        id: 'our_services',
        defaultMessage: 'Our services',
      }),
      icon: <ListCheckIcon size={22} />,
      children: [
        {
          key: '7',
          label: (
            <Link href={routes.PAYROLL}>
              {intl.formatMessage({
                id: 'nav.payroll',
                defaultMessage: 'Global Payroll',
              })}
            </Link>
          ),
        },
        {
          key: '8',
          label: (
            <Link href={routes.EOR}>
              {intl.formatMessage({
                id: 'nav.EOR',
                defaultMessage: 'Employer of Record',
              })}
            </Link>
          ),
        },
      ],
    },
    {
      key: '2',
      icon: <PriceTag3LineIcon size={22} />,
      label: (
        <Link href={routes.PRICING}>
          {intl.formatMessage({
            id: 'nav.pricing.title',
            defaultMessage: 'Pricing',
          })}
        </Link>
      ),
    },
    data?.hasLogin
      ? {
          key: '3',
          icon: <Suitcase3LineIcon size={22} />,
          onClick: CloseDrawer,
          label: (
            <Link href={remotePath}>
              {intl.formatMessage({
                id: 'task_board',
                defaultMessage: 'Remote Jobs',
              })}
            </Link>
          ),
        }
      : null,
    {
      key: '4',
      icon: <EditBoxLineIcon size={22} />,
      label: (
        <Link href={blogPath}>
          {intl.formatMessage({
            id: 'nav.blog',
            defaultMessage: 'Blog',
          })}
        </Link>
      ),
    },
    !data?.hasLogin
      ? {
          key: '5',
          icon: <LogoutCircleRLineIcon size={22} />,
          onClick: CloseDrawer,
          label: (
            <Link href={loginPath}>
              {intl.formatMessage({
                id: 'nav.login',
                defaultMessage: 'Login',
              })}
            </Link>
          ),
        }
      : null,
    data?.hasLogin
      ? {
          key: '6',
          icon: <ListCheckIcon size={22} />,
          onClick: CloseDrawer,
          label: (
            <Link href={remoteOfficePath}>
              {intl.formatMessage({
                id: 'enter_virtual_office',
                defaultMessage: 'Enter Remote Office',
              })}
            </Link>
          ),
        }
      : null,
  ];
  return <Menu mode="inline" theme="dark" className={styles.hamMenu} items={items} />;
};

export default HamMenu;
