import type { Density, ImageProps } from 'ui/Image';

export const getResizeUrl = (url: string, width: string) =>
  `${url}?x-oss-process=image/resize,w_${width}`;

const getWebpResizeUrl = (url: string, width: string) =>
  `${url}?x-oss-process=image/resize,w_${width}/format,webp`;

const getWidthXDensity = (width: string, density: string): string => {
  const densityNum = Number(density.replace('x', ''));
  return String(Number(width) * densityNum);
};

const getResizeDensityUrl = (url: string, width: string, density: Density) =>
  `${getResizeUrl(url, getWidthXDensity(width, density))} ${density}`;

const getWebResizeDensityUrl = (url: string, width: string, density: Density) =>
  `${getWebpResizeUrl(url, getWidthXDensity(width, density))} ${density}`;

type ScrSetFunc = typeof getResizeDensityUrl | typeof getWebResizeDensityUrl;

const getSrcSet = (url: string, width: string, srcSetFunc: ScrSetFunc): string => {
  const desities: Density[] = ['1x', '2x', '3x'];
  return desities.reduce((srcSet, density, index) => {
    if (index === desities.length - 1) {
      // last one
      return `${srcSet}${srcSetFunc(url, width, density)}`;
    }
    return `${srcSet}${srcSetFunc(url, width, density)},`;
  }, '');
};

export const getWebpResource = (url: string, width: string): ImageProps['sources'] => [
  { srcSet: getSrcSet(url, width, getWebResizeDensityUrl), type: 'image/webp' },
];

export const getImageSrcSet = (url: string, width: string): string =>
  getSrcSet(url, width, getResizeDensityUrl);

// --- with useWebpSupportCheck hook ---
export const getUrlByWebpSupport = (
  imageUrl: string,
  isSupportWebpDetected: boolean,
  isSupportWebp: boolean,
) => {
  const addWebpUrl = (url: string) => `${url}/format,webp`;
  return !isSupportWebpDetected
    ? addWebpUrl(imageUrl)
    : isSupportWebp
    ? addWebpUrl(imageUrl)
    : imageUrl;
};
