export type ImageProps = {
  className?: string;
  sources?: ImageSource[];
  width: string;
  height: string;
  src: string;
  srcSet?: string;
  alt?: string;
  loading?: 'lazy' | 'eager';
};

export type Density = '1x' | '2x' | '3x';

type ImageSource = {
  srcSet: string;
  type: string | undefined;
};

const Image = ({
  className = '',
  sources = [],
  width,
  height,
  src,
  srcSet,
  alt = 'img',
  loading = 'lazy',
}: ImageProps) => {
  return (
    <div className={className}>
      <picture>
        {sources.map((source) => (
          <source key={source.srcSet} srcSet={source.srcSet} type={source.type} />
        ))}
        <img width={width} height={height} src={src} srcSet={srcSet} alt={alt} loading={loading} />
      </picture>
    </div>
  );
};

export default Image;
