import { Menu } from 'antd';
import Link from 'next/link';
import { useIntl } from 'react-intl';

import config from 'common/config';
import { useAppDispatch, useAppSelector } from 'common/hooks/useReduxs';
import { setIsLogin } from 'common/redux/reducers/users';
import { useGetWebpackQuery } from 'common/services/webpack';
import { routes } from 'common/utils/constant';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import styles from './appMenu.module.less';

type AppMenuProps = {
  isHeaderBGChanged: boolean;
  transparent?: boolean;
};

const AppMenu = ({ isHeaderBGChanged, transparent = false }: AppMenuProps) => {
  const intl = useIntl();
  const [remotePath, setRemotePath] = useState<string>('');
  const [remoteOfficePath, setRemoteOfficePath] = useState<string>('');
  const [blogPath, setBlogPath] = useState<string>('');
  const router = useRouter();
  const isLogin = useAppSelector((state) => state.user.isLogin);
  const { data } = useGetWebpackQuery();
  const dispatch = useAppDispatch();

  useEffect(() => {
    setRemotePath(`${config.jobsUrl}/${router.locale}`);
    setRemoteOfficePath(`${config.oldWebUrl}/console/v2/`);
    setBlogPath(`${config.blogUrl}/${intl.locale === 'tw' ? '' : intl.locale === 'cn' ? 'zh-cn' : 'en'}`);
    if (!isLogin) {
      if (data && data.hasLogin) {
        dispatch(setIsLogin(data.hasLogin));
      }
    }
  }, [data, dispatch, isLogin, router.locale, intl.locale]);

  const items = [
    {
      key: 'sub-service',
      label: intl.formatMessage({
        id: 'our_services',
        defaultMessage: 'Our services',
      }),
      className: `${isHeaderBGChanged ? styles.textDark : styles.textLight} ${
        isHeaderBGChanged ? styles.textHover : styles.hoverBlueBg
      }`,
      popupClassName: `${isHeaderBGChanged ? styles.popupWhiteBG : styles.popupDarkBG}`,
      children: [
        {
          key: 'payroll',
          label: (
            <Link href={routes.PAYROLL}>
              {intl.formatMessage({
                id: 'nav.payroll',
                defaultMessage: 'Payroll',
              })}
            </Link>
          ),
        },
        {
          key: 'EOR',
          label: (
            <Link href={routes.EOR}>
              {intl.formatMessage({
                id: 'nav.EOR',
                defaultMessage: 'EOR',
              })}
            </Link>
          ),
        },
      ],
    },
    {
      key: 'pricing',
      label: (
        <Link href={routes.PRICING}>
          <a className={isHeaderBGChanged ? styles.textDark : styles.textLight}>
            {intl.formatMessage({ id: 'nav.pricing.title', defaultMessage: 'Pricing' })}
          </a>
        </Link>
      ),
    },
    isLogin
      ? {
          key: 'task-board',
          label: (
            <Link href={remotePath}>
              <a className={isHeaderBGChanged ? styles.textDark : styles.textLight}>
                {intl.formatMessage({ id: 'task_board', defaultMessage: 'Remote Jobs' })}
              </a>
            </Link>
          ),
        }
      : null,
    {
      key: 'blog',
      label: (
        <Link href={blogPath}>
          <a className={isHeaderBGChanged ? styles.textDark : styles.textLight}>
            {intl.formatMessage({ id: 'nav.blog', defaultMessage: 'Blog' })}
          </a>
        </Link>
      ),
    },
    isLogin
      ? {
          key: 'enter_virtual_office',
          label: (
            <Link href={remoteOfficePath}>
              <a className={isHeaderBGChanged ? styles.textDark : styles.textLight}>
                {intl.formatMessage({
                  id: 'enter_virtual_office',
                  defaultMessage: 'Enter Remote Office',
                })}
              </a>
            </Link>
          ),
        }
      : null,
  ];
  return (
    // 這裡會用inline style 去處理backgroundColor是為了避免AppMenu轉換背景顏色與AppHeader不一致
    <Menu
      className={
        isHeaderBGChanged ? styles.menuLight : transparent ? styles.transparent : undefined
      }
      disabledOverflow
      mode="horizontal"
      theme="dark"
      selectable={false}
      style={
        transparent
          ? { backgroundColor: 'transparent' }
          : isHeaderBGChanged
          ? { backgroundColor: 'white' }
          : undefined
      }
      items={items}
    />
  );
};

export default AppMenu;
