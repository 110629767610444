import Link from 'next/link';

export type BlankLinkProps = {
  href: string;
  content: React.ReactElement | string;
  isTrusted: boolean;
};

const BlankLink = ({ href, content, isTrusted }: BlankLinkProps) => {
  return (
    <Link href={href}>
      {isTrusted ? (
        <a target="_blank" rel="noopener">
          {content}
        </a>
      ) : (
        <a target="_blank" rel="noopener noreferrer">
          {content}
        </a>
      )}
    </Link>
  );
};

export default BlankLink;
