import { Button } from 'antd';
import config from 'common/config';
import Link from 'next/link';
import { useIntl } from 'react-intl';

import styles from './loginBtn.module.less';

type LoginBtnProps = {
  isHeaderBGChanged: boolean;
};

const LoginBtn = ({ isHeaderBGChanged }: LoginBtnProps) => {
  const intl = useIntl();

  return (
    <div className={styles.linkBtn}>
      <Link href={`${config.authUrl}/${intl.locale}/login`}>
        <Button
          type="ghost"
          shape="round"
          className={isHeaderBGChanged ? styles.greenLoginBtn : styles.whiteLoginBtn}
        >
          {intl.formatMessage({
            id: 'nav.login',
            defaultMessage: 'Login',
          })}
        </Button>
      </Link>
    </div>
  );
};

export default LoginBtn;
