import { useEffect, useState } from 'react';

const useHeaderBGStatus = () => {
  const THRESHOLD_Y = 80;
  const [isHeaderBGChanged, setIsChanged] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > THRESHOLD_Y) {
        setIsChanged(true);
      } else {
        setIsChanged(false);
      }
    };

    window.addEventListener('scroll', handleScroll, true);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return isHeaderBGChanged;
};

export default useHeaderBGStatus;
